@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  scroll-margin-top: 500px;
}
@media (max-width: 768px) {
  html {
    scroll-margin-top: 1000px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  letter-spacing: 0.1em;
}
p {
  margin-bottom: 1rem;
}
.text-primary-light {
  color: #1d9bc2;
}
.inline-block {
  display: inline-block;
}
.whitespace-nowrap {
  white-space: nowrap;
}

.bg-dark-slate {
  background-color: #7d8ba0;
}

.bg-gradiant-fade {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 60%
  );
}

.text-center-md-left {
  text-align: left;
}
@media (max-width: 868px) {
  .text-center-md-left {
    text-align: center;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 14px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e5e8ec;
  border-radius: 7px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #0074bd;
  border-radius: 7px;
}

.text-stroke-red {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #e40922;
  /* filter: drop-shadow(0px 1.5px 0px #e40922) drop-shadow(1.5px 1.5px 0px #e40922) drop-shadow(1.5px 0px 0px #e40922); */
}

.stretch {
  display: inline-block;
  /* margin: auto 9px auto 3px; */
  -webkit-transform: skewX(4deg);
  -moz-transform: skewX(4deg);
  -o-transform: skewX(4deg);
  transform: skewX(4deg);
  font-family: var(--font-wills);
  font-weight: 300;
}

.no-dot-two-columns {
  columns: 2;
  list-style-type: none !important;
  list-style-position: outside !important;
  margin-left: auto !important;
}
.no-dot-two-columns li {
  list-style-type: none !important;
  list-style-position: outside !important;
}

@media (max-width: 768px) {
  .no-dot-two-columns {
    columns: 1;
  }
}

@media (min-width: 768px) {
  .borderBetween:not(:last-of-type) {
    border-right: 2px solid #e40922;
  }
}

/* Marquee */
/* .marquee-styles .initial-child-container,
.marquee {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  height: fit-content;
} */

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 206px;
  overflow-x: hidden;
}

.marquee .track {
  position: absolute;
  white-space: nowrap;
}

.marquee .track > h1 {
  margin: 20px 0;
  font-size: 8rem;
  font-family: Antonio;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #f4955c;
  text-transform: uppercase;
}

/* Swiper */
.swiper-wrapper {
  order: 1;
}
.swiper-button-prev,
.swiper-button-next {
  position: relative;
  color: #161c25;
  height: 3rem;
  width: 3rem;
  margin-top: 0px;
}
.swiper-button-prev {
  order: 2;
}
.swiper-button-next {
  order: 4;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 1.8rem;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #0074bd;
}
.swiper-pagination {
  position: relative;
  order: 3;
  width: fit-content !important;
  bottom: unset !important;
  margin: auto 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.swiper-pagination-bullet {
  background: hwb(214 90% 37%);
  width: 12px !important;
  height: 12px !important;
}
.swiper-pagination-bullet-active {
  background: #e40922;
}

/* Gravity Forms */
.gfield:not(.gfield-CHECKBOX):not(.gfield-RADIO) legend,
.gfield:not(.gfield-CHECKBOX):not(.gfield-CONSENT):not(.gfield-RADIO) label {
  display: none !important;
}

#field_5_10 > div:nth-child(2) > label,
#field_5_10 > div:nth-child(3) > label,
#field_5_10 > div:nth-child(4) > label{
  display: inline-block !important;
  font-family: var(--font-body),sans-serif  !important;
  color: #9CA3AF;
}

.gfield input,
.gfield textarea {
  border: 1px solid #1d9bc2 !important;
  border-radius: 3px !important;
}
.gfield :focus-visible {
  outline-color: #1d9bc2 !important;
}

.Toastify__toast-container--bottom-center {
  left: 45%;
}
.Toastify__toast-theme--light {
  width: 100%;
  max-width: 450px;
  border: 4px solid #1d9bc2;
  border-radius: 10px;
  background: #e5e8ec;
  color: #161C25;
}