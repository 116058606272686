.content-wrapper a {
  @apply text-blue-500;
}

.content-wrapper ul {
  @apply ml-6 list-disc;
}

.content-wrapper ul li {
  @apply list-disc;
}

.content-wrapper ol {
  @apply ml-6 list-decimal;
}

.content-wrapper ol li {
  @apply list-decimal;
}

.content-wrapper ul ol {
  @apply ml-6 list-decimal;
}

.content-wrapper ul li ol li {
  @apply list-decimal;
}

.content-wrapper ol li ul {
  @apply ml-6 list-disc;
}

.content-wrapper ol li ul li {
  @apply list-disc;
}
